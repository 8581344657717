<template>
  <div>
      <b-container fluid>
        <h4>Setari sistem</h4>
        <b-row>
            <b-col md="4">
                s
            </b-col>
        </b-row>
      </b-container>
  </div>
</template>

<script>
import config from '@/config'

export default {
  name: 'General',
  mounted () {
    this.getData()
  },
  data () {
    return {
      data: []
    }
  },
  computed: {
    apiUrl () {
      return config.baseApiUrl + '/api/cars'
    }
  },
  methods: {
    getData () {
      this.$axios.get(this.apiUrl)
        .then((response) => {
          this.data = response.data.data
        })
    },
    remove (id) {
      if (confirm('Remove?')) {
        this.$axios.delete(config.baseApiUrl + '/api/cars/' + id)
          .then(() => {
            this.getData()
          })
      }
    }
  }
}
</script>

<style scoped>
  .table tr th,
  .table tr td {
    vertical-align: middle;
  }
</style>
